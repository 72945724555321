import React from 'react';
import { DaysTo } from './styles/countdownStyled.js';

class CountDown extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            daysTo: null,
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            const dateTo = this.props.dateTo;
            const now = new Date().getTime();
            const distance = dateTo - now;
            
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
            if (distance < 0) {
                this.setState({
                    daysTo: 'Today!',
                })
            }
    
            this.setState({
                daysTo: days + ' days ' + hours + 'h ' + minutes + 'm ' + seconds + 's',
            });
        }, 1000);
    }
      
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(){
        const { daysTo } = this.state;

        return(
            <DaysTo>{ daysTo }</DaysTo>
        )
    }
}

export default CountDown;
