import styled from 'styled-components';

export const DaysTo = styled.p`
    color: #7d7d7d;
    font-size: 2.2rem;
    font-family: Halant, sans-serif;
    padding: 15px 0;
    @media all and (max-width: 550px){
        font-size: 1.4rem;
    }
`;
