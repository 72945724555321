import styled from 'styled-components';
import Img from 'gatsby-image'

export const Image = styled(Img)``;

export const MainWrapper = styled.section`
    display: grid;
    grid-template-columns: 3fr 2fr;
    @media all and (max-width: 770px){
        display: initial;
    }
`;

export const ImgWrapper = styled.div`
    padding: 60px 30px;
    background-color: rgba(241, 241, 241, .75);
    text-align: right;
    max-height: 450px;
    ${Image}{
        height: 550px;
    }
    @media all and (max-width: 550px){
        ${Image}{
            height: 450px;
        }
    }
`;

export const TitleWrapper = styled.div`
    position: relative;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: middle;
    justify-content: center;
    padding: 0 20px 0 40px;
    margin-top: 100px;
    @media all and (max-width: 770px){
        margin: 0;
        padding: 30px;
    }
`;

export const Title = styled.h1`
    font-size: 2.2rem;
    line-height: 1.2;
    color: #7d7d7d;
    font-family: Halant, sans-serif;
    margin: 0;
    span {
        color: orange;
        font-size: 6rem;
        padding: 0 5px;
        position: relative;
        top: 1rem;
        opacity: .75;
    }
    @media all and (max-width: 1120px){
        font-size: 1.8rem;
    }
    @media all and (max-width: 924px){
        font-size: 1.4rem;
    }
    @media all and (max-width: 550px){
        span {
            font-size: 3rem;
        }
    }
`;

export const SubTitle = styled.h2`
    color: #7d7d7d;
    font-size: 1.8rem;
    margin-bottom: 0;
    @media all and (max-width: 1120px){
        font-size: 1.4rem;
    }
    @media all and (max-width: 924px){
        font-size: 1.2rem;
    }
`;

export const Text = styled.p`
    color: #7d7d7d;
    width: 60%;
    margin: 20px auto;
    @media all and (max-width: 550px){
        width: 100%;
        margin: 10px 0 0;
        padding-bottom: 0;
    }
`;

export const MenuButton = styled.a`
    text-decoration: none;
    width: 150px;
    margin: 0 auto;
    color: #313131;
    border: 2px solid orange;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1.2em;
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    &:hover {
        transform: scale(1.12);
        background-color: orange;
        color: white;
    }
`;
