import styled from 'styled-components';

export const OrderWrapper = styled.section`
    padding: 75px 0;
    border-top: 1px solid rgba(241,241,241,.75);
    border-bottom: 1px solid rgba(241,241,241,.75);
    @media all and (max-width: 650px){
        padding: 30px;
    }
`;

export const Order = styled.div`
    width: 70vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 650px){
        width: 100%;
    }
`;

export const Title = styled.h2`
    font-family: Halant,sans-serif;
    font-size: 2.2rem;
    &:after {
        content: '';
        display: block;
        width: 40px;
        height: 4px;
        background-color: orange;
        margin: 20px auto;
    }
`;

export const Items = styled.ul`
    padding-top: 20px;
    padding-left: 50px;
    border-left: 1px solid lightgrey;
    list-style-type: none;
`;

export const Item = styled.li`
    p {
        font-size: 1.6em;
        &:first-child {
            margin-bottom: 5px;
            padding: 0;
            font-family: Halant,sans-serif;
        }
        &:last-child {
            font-style: italic;
            color: grey;
            font-size: 1.2em;
            line-height: 1.2;
            margin-bottom: 20px;
            width: 80%
        }
    }
    span {
        color: grey;
    }
    position: relative;
    &:before {
        content: '';
        display: block;
        border: 1px solid orange;
        background-color: white;
        width: 20px;
        height: 20px;
        position: absolute;
        left: -60px;
        transform: rotate(45deg);
    }
`;
