import React from 'react';
import { StaticQuery, graphql } from 'gatsby'

import {
    GiftsSection,
    Title,
    Bride,
    HelpSection,
    FlexWrap
} from './styles/giftsStyled';

class Gifts extends React.Component {
    render(){
        const { data } = this.props;
        return(
            <>
                <GiftsSection>
                    <Title>Gifts</Title>
                    <p>{data.contentJson.gifts.intro}</p>
                    <p>{data.contentJson.gifts.info}</p>
                    <p>{data.contentJson.gifts.other}</p>
                </GiftsSection>
                <HelpSection>
                    <Title>Contact us</Title>
                    <p>Please, do not hesitate to contact us if you have any questions about the day or anything at all! We do hope you can help celebrate our special day with us!</p>
                    <FlexWrap>
                        <Bride>
                            <p>Lauren: <span>07446045392</span></p>
                        </Bride>
                        <Bride>
                            <p>Daniella: <span>07950419332</span></p>
                        </Bride>
                    </FlexWrap>
                </HelpSection>
            </>
        )
    }
}

const query = graphql`
    query GiftContent {
        contentJson {
            gifts {
                intro
                info
                other
            }
            parking {
                intro
            }
        }
    }
`

export default () => 
    <StaticQuery 
        query={query} 
        render={ data => 
            <Gifts data={data}/>
        } 
    />