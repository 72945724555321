import styled from 'styled-components';
import Img from 'gatsby-image'

export const HotelWrapper = styled.section`
    background-color: rgba(241,241,241,.75);
    text-align: center;
    padding: 60px 20%;    
    @media all and (max-width: 975px){
        padding: 60px 10%;
    }
    @media all and (max-width: 650px){
        padding: 30px;
    }
`;

export const Title = styled.h2`
    font-family: Halant,sans-serif;
    font-size: 2.2rem;
    margin-bottom: 40px;
    &:after {
        content: '';
        display: block;
        width: 40px;
        height: 4px;
        background-color: orange;
        margin: 20px auto;
    }
`;

export const Intro = styled.p`
    font-size: 1.4em;
`;

export const AllHotels = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media all and (max-width: 575px){
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
        grid-gap: 20px;
    }
`;

export const HotelCard = styled.div`
    background: white;
    width: 25%;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    @media all and (max-width: 575px){
        width: 100%;
    }

    &:hover {
        transform: scale(1.1);
        box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.5);
    }

    p {
        font-size: 1.2em;
        padding: 10px;
    }
`;

export const Image = styled(Img)`
    height: 180px;
    width: 100%;
    margin-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;