import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {
    RsvpWrapper,
    Form,
    RsvpTitle,
    FormConfirmation,
    Group,
} from './styles/rsvpStyled.js'

const Rsvp = ({ data }) => (
    <StaticQuery
        query={graphql`
            query RsvpContent {
                contentJson {
                    rsvp {
                        info
                    }
                }
            }
        `}
        render={ data => (
            <RsvpWrapper>
                <RsvpTitle>RSVP</RsvpTitle>
                <p>{data.contentJson.rsvp.info}</p>
                <RsvpForm />
            </RsvpWrapper>
        )}
    />
);

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class RsvpForm extends React.Component{

    constructor(){
        super()

        this.state = {
            guestName: '',
            email: '',
            rsvp: '',
            // message: '',
            showForm: true,
        }
    }

    handleSubmit = e => {
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'rsvp', ...this.state })
        })
            .then(() => setTimeout(() => {this.clearForm(this.state)}, 500 ))
            .catch(error => alert(error));

        e.preventDefault();
    };
    
    handleRsvp = e => this.setState({rsvp: e.target.value});
    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    clearForm = (body) => {
        console.log('form stuff', body)
        this.setState({
            guestName: '',
            email: '',
            // message: '',
            rsvp: '',
        });
        setTimeout(() => { this.setState({ showForm: false }) }, 750);
    }

    render(){
        const { email, guestName, showForm } = this.state;
        return(
            <React.Fragment>
                <Form name="rsvp" showForm={showForm} method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
                    <input type="hidden" name="form-name" value="rsvp" />                    
                    <label>Name(s): <input type="text" name="guestName" onChange={this.handleChange} value={guestName} required/></label>
                    <span>* - please include the names of each guest able to attend</span>
                    <label>Email: <input type="email" name="email" onChange={this.handleChange} value={email} required/></label>
                    <Group>
                        <div>
                            <input type="radio" id="yes" name="rsvp" onChange={this.handleRsvp} value="Yes, can't wait!"/>
                            <label htmlFor="yes">Can't wait!</label>
                        </div>
                        <div>
                            <input type="radio" id="no" name="rsvp" onChange={this.handleRsvp} value="Nah, hate you!"/>
                            <label htmlFor="no">Sorry, can't make it!</label>
                        </div>
                    </Group>
                    {/* <label>Message: <textarea name="message" onChange={this.handleChange} value={message} /></label> */}
                    <input type="submit" value="RSVP"/>
                </Form>
                <FormConfirmation showForm={!showForm}>Thank you!</FormConfirmation>
            </React.Fragment>
        )
    }
}

export default Rsvp;

