import React from 'react';
import { StaticQuery, graphql } from 'gatsby'

import {
    HotelWrapper,
    Title,
    Intro,
    HotelCard,
    AllHotels,
    Image,
} from './styles/hotelsStyled';

class Hotels extends React.Component {
    loadHotel(url){
        window.open(url, '_blank');
    }
    render(){
        const { data } = this.props;
        const hotels = data.contentJson.accommodation.hotels;
        return(
            <HotelWrapper>
                <Title>Nearby Hotels</Title>
                <Intro>{data.contentJson.accommodation.intro}</Intro>
                <AllHotels>
                    {hotels.map((hotel) => {
                        return(
                            <HotelCard key={hotel.name} onClick={() => this.loadHotel(hotel.url)}>
                                <Image fluid={hotel.img.childImageSharp.fluid} />
                                <p>{hotel.name}</p>
                            </HotelCard>
                        )
                    })}
                </AllHotels>
            </HotelWrapper>
        );
    }
}

const query = graphql`
    query HotelContent {
        contentJson {
            accommodation {
                intro
                airbnb
                hotels {
                    name
                    url
                    img {
                        childImageSharp {
                            fluid(maxWidth: 520) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`

export default () => 
    <StaticQuery 
        query={query} 
        render={ data => 
            <Hotels data={data}/>
        } 
    />