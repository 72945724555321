import styled from 'styled-components';

export const GiftsSection = styled.section`
    padding: 60px 20%;
    text-align: center;
    @media all and (max-width: 650px){
        padding: 30px;
    }
`;

export const Title = styled.h2`
    font-family: Halant,sans-serif;
    font-size: 2.2rem;
    margin-bottom: 40px;
    text-align: center;
    &:after {
        content: '';
        display: block;
        width: 40px;
        height: 4px;
        background-color: orange;
        margin: 20px auto;
    }
`;

export const HelpSection = styled.section`
    background-color: rgba(241,241,241,.75);
    padding: 60px 30%;
    text-align: center;
    @media all and (max-width: 650px){
        padding: 30px;
    }
`;

export const FlexWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0 0;
    @media all and (max-width: 650px){
        flex-direction: column;
        padding: 0;
    }
`;

export const Bride = styled.div`
    font-family: Halant,sans-serif;
    font-size: 1.5em;
    span {
        color: #adadad;
    }
    @media all and (max-width: 650px){
        padding-bottom: 10px;
        &:last-child {
            padding: 0;
        }
    }
`