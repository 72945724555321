import styled from 'styled-components';
import Img from 'gatsby-image'

export const VenueWrapper = styled.section`
    display: flex;
    flex-direction: row;
    height: 450px;
    @media all and (min-width: 1040px){
        height: 600px;
    }
    @media all and (max-width: 660px){
        flex-direction: column;
        height: auto;
    }
`;

export const Image = styled(Img)`
    width: 50%;
    height: 100%;
    @media all and (max-width: 660px){
        width: 100%;
    }
`;

export const Content = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    text-align: center;
    h2 {
        font-size: 2.2rem;
        &:after {
            content: '';
            display: block;
            width: 40px;
            height: 4px;
            background-color: orange;
            margin: 20px auto;
        }
    }
    h2, h3 {
        font-family: Halant,sans-serif;
    }
    @media all and (max-width: 660px){
        width: 100%;
        padding: 30px;
    }
`;

export const View = styled.a`
    text-decoration: none;
    color: #313131;
    border: 2px solid orange;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1.2em;
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    &:hover {
        transform: scale(1.2);
        background-color: orange;
        color: white;
    }
`;