import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CountDown from '../components/countdown';

import {
    MainWrapper,
    ImgWrapper,
    Image,
    TitleWrapper,
    Title,
    SubTitle,
    Text,
    MenuButton,
} from './styles/mainStyled.js'

const iDoDate = new Date("October 28, 2019 00:00:00").getTime();

const Main = ({ data, menu}) => (
    <StaticQuery
        query={graphql`
            query MainContent {
                beer: file(relativePath: { eq: "beer.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 600) {
							...GatsbyImageSharpFluid
						}
					}
                }
                laurendani: file(relativePath: { eq: "laurendani_2.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 600) {
							...GatsbyImageSharpFluid
						}
					}
                }
                contentJson {
                    main {
                        bride_1
                        bride_2
                        tag
                        content
                        menu
                    }
                }
            }
        `}
        render={ data => (
            <MainWrapper>
                {menu ?
                    <TitleWrapper>
                        <Title>Wedding Breakfast</Title>
                        <Text>Please take a look at the menu and submit your choices by the 2nd September. If you have any dietry requirements please include these within the form.</Text>
                    </TitleWrapper>
                :
                    <TitleWrapper>
                        <Title>{data.contentJson.main.bride_2} <span>&amp;</span> {data.contentJson.main.bride_1}</Title>
                        <SubTitle>{data.contentJson.main.tag}</SubTitle>
                        <Text>{data.contentJson.main.content}</Text>
                        <CountDown dateTo={iDoDate} />
                        <MenuButton href="/menu">View Menu</MenuButton>
                    </TitleWrapper>
                }
                <ImgWrapper>
                    { menu ? <Image fluid={data.beer.childImageSharp.fluid} />
                    : <Image fluid={data.laurendani.childImageSharp.fluid} /> }
                </ImgWrapper>
            </MainWrapper>
        )}
    />
);

export default Main;

