import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import {
    VenueWrapper,
    Content,
    Image, 
    View,
} from './styles/venueStyled'

const Venue = () => (
    <StaticQuery
        query={graphql`
            query VenueContent {
                breweryImage: file(relativePath: { regex: "/brewery/" }) {
                    childImageSharp {
                        fluid(maxWidth: 720) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                contentJson {
                    venue {
                        title
                        where
                        info
                    }
                }
            }
        `}
        render={data => (
            <VenueWrapper>
                <Image fluid={data.breweryImage.childImageSharp.fluid} />
                <Content>
                    <h2>Details</h2>
                    <h3>{data.contentJson.venue.title}</h3>
                    <p>{data.contentJson.venue.where}</p>
                    <p>{data.contentJson.venue.info}</p>
                    <View href="https://www.wylambrewery.co.uk/" target="_blank">View Venue</View>
                </Content>
            </VenueWrapper>
        )}
    />
);

export default Venue;

