import styled from 'styled-components';

export const RsvpWrapper = styled.section`
    padding: 0 5rem;
    margin-top: 225px;
    background-color: rgba(241, 241, 241, .75);
    padding: 50px;
    text-align: center;
    @media all and (max-width: 650px){
        padding: 30px;
    }
    @media all and (max-width: 550px){
        margin-top: 100px;
    }
`;

export const RsvpTitle = styled.h1`
    display: inline-block;
    margin-bottom: 0;
    font-family: Halant,sans-serif;

    &:after {
        content: '';
        display: block;
        width: 40px;
        height: 4px;
        background-color: orange;
        margin: 20px auto;
    }
`;

export const Group = styled.div`
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border: none;
    label {
        padding: 3px 0;
    }
    @media all and (max-width: 650px){
        flex-direction: column;
    }
`;

export const Form = styled.form`
    width: 37.5vw;
    max-width: 100%;
    background-color: transparent;
    margin: 20px auto 0;
    padding: 20px 0 0;
    text-align: center;
    font-size: 1.2em;
    display: ${props => props.showForm ? 'block' : 'none'};

    @media all and (max-width: 850px){
        width: 70vw;
    }

    @media all and (max-width: 650px){
        width: 100%;
    }

    p {
        margin: 10px 0 0;
        padding: 0;
        font-size: .89rem;
        text-align: left;
    }

    input {
        width: 100%;
        border: 0;
        height: 40px;
        margin-bottom: 10px;
        transition: box-shadow 0.3s ease-in-out;
        &:focus {
            outline: none;
            border: 2px solid orange;
        }
    }

    label {
        width: 100%;
        display: block;
        text-align: left;
        font-size: .89rem;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #ffffff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: orange;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    textarea {
        display: block;
        border: 2px solid transparent;
        margin-top: 5px;
        width: 100%;
        border: 0;
        resize: none;
        height: 80px;
        transition: box-shadow 0.3s ease-in-out;
        &:focus {
            outline: none;
            border: 2px solid orange;
        }
    }

    input[type="submit"]{
        border: 0;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 0;
        width: 5rem;
        border-radius: 15px;
        background-color: orange;
        color: white;
        height: 30px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.2);
        }
    }

    span {
        text-align: left;
        font-style: italic;
        font-size: 1em;
        color: lightgrey;
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }

`;

export const FormConfirmation = styled.p`
    visibility: ${props => props.showForm ? 'visible' : 'hidden'};
    height: ${props => props.showForm ? 'auto' : 0};
    transition: all 0.3s ease-in-out;
`;
