import React from 'react';
import { StaticQuery, graphql } from 'gatsby'

import {
    OrderWrapper,
    Order,
    Title,
    Items,
    Item
} from './styles/orderStyled';

class OrderOfTheDay extends React.Component {
    render(){
        const { data } = this.props;
        const order = data.contentJson.orderOfTheDay;
        return(
            <OrderWrapper>
                <Order>
                    <Title>Order of the Day</Title>
                    <Items>
                        {order.map(item => {
                            return(
                                <Item key={item.time}>
                                    <p>{item.time} - <span>{item.event}</span></p>
                                    <p>{item.info}</p>
                                </Item>
                            )
                        })}
                    </Items>
                </Order>
            </OrderWrapper>
        );
    }
}

const query = graphql`
    query OrderContent {
        contentJson {
            orderOfTheDay {
                time
                event
                info
            }
        }
    }
`

export default () => 
    <StaticQuery 
        query={query} 
        render={ data => 
            <OrderOfTheDay data={data}/>
        } 
    />