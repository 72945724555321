import React from 'react'
import Layout from '../components/layout'

import Main from '../components/main'
import Rsvp from '../components/rsvp'
import Venue from '../components/venue';
import OrderOfTheDay from '../components/order';
import Hotels from '../components/hotels';
import Gifts from '../components/gifts';

/**
 * Import each content block as component
 * - header 
 * - main
 * - rsvp 
 * - countdown
 * - order of the day
 * - menu
 * - parking
 * - accommodation 
 * - gifts
 */

class IndexPage extends React.Component {
	render(){
		return (
			<Layout>
				<Main />
				<Rsvp />
				<Venue />
				<OrderOfTheDay />
				<Hotels />
				<Gifts />
			</Layout>
		)
	}
}

export default IndexPage;
